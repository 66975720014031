import Form from "react-bootstrap/Form";
import Figure from "react-bootstrap/Figure";
import Stack from "react-bootstrap/Stack";
import { Link, useNavigationType } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUser = () => {
    const value = { email, name };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(value),
    };
    fetch("http://localhost:3360/post", request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Cannot fetch data");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error occurs when fetching", error);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    fetchUser();
  }

  function handleChange(event) {
    setEmail(event.target.value);
  }
  return (
    <Form className="loginBox" onSubmit={handleSubmit}>
      <Figure className="figure">
        <a href="https://www.freepik.com/author/stories">
          Designed by / Freepik
        </a>
        <Figure.Image src="/images/4957136.jpg" className="imageLog" />
      </Figure>
      <Stack className="formLogin" gap={4}>
        <Form.Group>
          <Form.Label>Firstname</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Lastname</Form.Label>
          <Form.Control type="text" placeholder="Last Name" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Number</Form.Label>
          <Form.Control type="text" placeholder="Phone Number" />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control type="password" placeholder="New password" />
        </Form.Group>

        <Button variant="primary" size="lg" type="submit">
          Register
        </Button>
      </Stack>
    </Form>
  );
};

export default Register;
