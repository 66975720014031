import Form from "react-bootstrap/Form";
import Figure from "react-bootstrap/Figure";
import Stack from "react-bootstrap/Stack";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContextCart } from "../context/Context";

const Login = () => {
  const { setUserState } = useContext(ContextCart);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
    if (user === true) {
      console.log("it is true");
    }
  }, [user]);

  const fetchUser = () => {
    const value = { email };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(value),
    };
    fetch("http://localhost:3360/login", request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Cannot fetch data");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.success, data);
        setUser(data.success);
        setUserState(true);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error occurs when fetching", error);
        setUser(false);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    fetchUser();
  }

  function handleChange(event) {
    setEmail(event.target.value);
  }
  return (
    <Form className="loginBox" onSubmit={handleSubmit}>
      <Figure className="figure">
        <a href="https://www.freepik.com/author/stories">
          Designed by / Freepik
        </a>
        <Figure.Image src="/images/4957136.jpg" className="imageLog" />
      </Figure>
      <Stack className="formLogin" gap={4}>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
        <Form.Group className="forgotBox">
          <Form.Check
            style={{ width: "350px" }}
            type="checkbox"
            label="Remember me"
            id="checkbox"
          />

          <Link to="password">Forgot password?</Link>
        </Form.Group>
        <Button variant="primary" size="lg" type="submit">
          Sign in
        </Button>

        <Form.Label className="labelMember">
          Not a member?{" "}
          <span className="blue">
            <Link>Register</Link>
          </span>
        </Form.Label>
      </Stack>
    </Form>
  );
};

export default Login;
