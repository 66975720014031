const Contact = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <h4 className="contactH4">Contact Us</h4>
            <ul className="list-unstyled">
              <li>Phone : 88498</li>
              <li>Email Us</li>
              <li>Live Chat</li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-6">
            <h4 className="contactH41">Cheese</h4>
            <ul className="list-unstyled">
              <li>Hee</li>
              <li>Kuay</li>
              <li>Jek</li>
              <li>Muay</li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-6">
            <h4 className="contactH4">Coccccccccccc</h4>
            <ul className="list-unstyled">
              <li>Hee</li>
              <li>Kuay</li>
              <li>Jek</li>
              <li>Muay</li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-6">
            <h4 className="contactH4">Hummmmm</h4>
            <ul className="list-unstyled">
              <li>Hee</li>
              <li>Kuay</li>
              <li>Jek</li>
              <li>Muay</li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="text-xs-center">
            &copy;{new Date().getFullYear()} Shopping Vitamine - All Rights
            Reserved ®
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
