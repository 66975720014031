import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

const ItemInCheckout = ({ product }) => {
  return (
    <Card
      style={{
        marginLeft: "20px",
        marginTop: "20px",
        width: "90%",
        height: "140px",
        overflow: "hidden",
      }}
    >
      <div className="row no-gutters">
        <div className="col-md-4" style={{ height: "100%" }}>
          <Card.Img
            src={product.image}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-md-8">
          <Card.Body>
            <Row xs={1} md={2} className="g-4">
              <Card.Title>{product.name}</Card.Title>
              <Card.Text>{product.price}</Card.Text>
              <Card.Text>{product.numReviews}</Card.Text>
              <Card.Text>{product.rating}</Card.Text>
            </Row>
          </Card.Body>
        </div>
      </div>
    </Card>
  );
};

export default ItemInCheckout;
