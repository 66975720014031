import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Header from "./components/Header";
import Contact from "./components/Contact";
import "./App.css";
import "./Check.css";
import "./Footer.css";
import "./Login.css";
import Checkout from "./checkouts/Checkout";
import Promotion from "./components/Promotion";
import Login from "./login/Login";
import Register from "./login/Register";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/promo" element={<Promotion />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Contact />
    </BrowserRouter>
  );
}

export default App;
