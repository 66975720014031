import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ContextCart } from "../context/Context";
import { useContext, useEffect, useState } from "react";
import ItemInCheckout from "./ItemInCheckout";
import Button from "react-bootstrap/esm/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

const Checkout = () => {
  const { cartItem } = useContext(ContextCart);
  const [totalPrice, setTotal] = useState(0);

  useEffect(() => {
    const total = cartItem.reduce(
      (total, product) => (total += product.price * product.quanity),
      0
    );
    setTotal(total);
  }, [cartItem]);
  return (
    <Container className="checkoutParent">
      <div className="checkItemContainer">
        {cartItem.length > 0 &&
          cartItem.map((products) => (
            <ItemInCheckout product={products} key={products._id} />
          ))}
      </div>

      <div className="payment">
        <h4>Summary </h4>
        <h6>Apply coupons</h6>
        <FloatingLabel
          controlId="floatingInput"
          label="COUPONS CODE"
          className="mb-3"
        >
          <Form.Control type="Code" placeholder="Hee" className="inputCode" />
        </FloatingLabel>
        <hr style={{ marginLeft: "15px", width: "88%" }} />
        <h6> This order ship is free</h6>
        <ProgressBar
          variant="info"
          now={100}
          style={{ marginLeft: "15px", width: "88%" }}
        />
        <div className="totalPrice flex">
          <p>subtotal </p>
          <p>totalprice</p>
        </div>

        <div className="shipping flex">
          <p>shipping</p>
          <p>FREE</p>
        </div>

        <div className="tax flex">
          <p>Estimated Tax</p>
          <p>000</p>
        </div>

        <div className="estimatedTotal flex">
          <p className="blue">Estimated Order Total</p>
          <p className="blue">{cartItem.length > 0 && totalPrice}</p>
        </div>
        <ButtonToolbar
          style={{
            padding: "5px",
            justifyContent: "center",
          }}
        >
          <Button
            variant="info"
            style={{ width: "90px", marginRight: "10px", fontSize: "10px" }}
          >
            Proceed to Checkout
          </Button>
          <Button
            variant="secondary"
            style={{ width: "90px", marginLeft: "10px", fontSize: "10px" }}
          >
            Checkout with MM
          </Button>
        </ButtonToolbar>
      </div>
    </Container>
  );
};

export default Checkout;
